<template>
    <div>

        <v-divider></v-divider>
        <HbPropertySelector
            id="facility"
            :items="facilityList"
            item-text="name"
            item-value="id"
            v-validate="'required'"
            v-model.lazy="property_id"
            data-vv-scope="gate"
            data-vv-name="facility"
            data-vv-as="Property"
            :error="errors.has('gate.facility')"
        />

        <div class="mr-6">

            <div class="mb-2" v-if="action == 'view'">
                <hb-breadcrumb @click="goBack">Back to All Group Profiles</hb-breadcrumb>
            </div>

            <div class="primary-section-content">

                <hb-empty-state 
                    class="mt-1"
                    message="Grouping profiles are generated groups defined by specific features and amenities as well as sizes or area tiers"
                    btn-txt="Add New Grouping Profile"
                    :disabled="!property_id" 
                    v-if="!spaceGroups.length" 
                    @clicked="addProfile"
                >
                </hb-empty-state>
                
                <group-settings :property_id="property_id" :group="selected" v-else-if="action ==='view'" @addProfile="addProfile" @fetchData="fetchData"></group-settings>
                
                <profile-list :property_id="property_id" :space-groups="spaceGroups" v-else-if="spaceGroups.length" @addProfile="addProfile" @view="viewGroup" @add="addProfile" @delete="deleteProfile" @update="updateProfile"></profile-list>
            </div>
            
        </div>


        <hb-modal v-model="isActionExists" show-help-link v-if="action === 'add'" size="large" title="Add New Grouping Profile" @close="closeWindow">
             <template v-slot:subheader> It is a combination of both Features/Amenities and Size/Area Tiers together that create your groups. For e.g, if you select “Climate Control” for Features and Amenities, and select “Group by Size” for Size/Area Tier, then the following groups are created: “Climate Control - 5x5”, “No Climate Control - 5x5", “Climate Control - 10x15”, “No Climate Control -10x15", etc.</template>
            
            <template v-slot:content >
                <div >
                    <hb-form label="Name" required> 
                        <hb-text-field
                            v-model="profile.name"
                            v-validate="'required|max:45'"
                            data-vv-scope="profile"
                            data-vv-as="Name"
                            id="name"
                            name="name" 
                            :error="errors.has('profile.name')"
                            placeholder="Enter a Name for This Grouping Profile"
                            autofocus
                        ></hb-text-field>
                    </hb-form>
 

                    <hb-form multiple label="Description" last>
                        <hb-textarea
                            v-model="profile.description"
                            v-validate="'max:1000'"
                            data-vv-scope="profile"
                            data-vv-as="Description"
                            id="description"
                            name="description" 
                            :error="errors.has('profile.description')"
                            placeholder="Enter a Description for this Grouping Profile"
                        ></hb-textarea>
                    </hb-form>
                </div>
            </template>
            <template v-slot:actions>
                <hb-btn :loading="isLoading($options.name)" color="primary" @click="saveProfile">Add Grouping Profile</hb-btn>
            </template>
        </hb-modal>

        <DeleteUpdateModal :action="action" :profileName="selected.name" @deleteProfile="deleteConfirm" @updateProfile="updateConfirm" @close="closeWindow"> </DeleteUpdateModal>

    </div>
</template>

<script type="text/babel">
    import Modal from '../../assets/Modal.vue';
    import EmptyStateContainer from '../../assets/EmptyStateContainer.vue'
    import GroupSettings from './GroupSettings.vue'
    import ProfileList from './ProfileList.vue'
    import DeleteUpdateModal from './DeleteUpdateModal.vue'

    import Loader from '../../assets/CircleSpinner.vue';
    import api from '../../../assets/api.js';
    import { EventBus } from '../../../EventBus.js';
    import { mapGetters, mapActions } from 'vuex';

    import { notificationMixin } from  '../../../mixins/notificationMixin.js';

    export default {
        name: "SpaceGroupIndex",
        mixins: [ notificationMixin ],
        props: {
            facility_id: {
                type : String,
                default: ''
            }
        },
        data() {
            return {
                profile: {
                    id: '',
                    name: '',
                    description:'',
                    is_default: 0,
                }, 
                spaceGroups:[],
                selected: {},
                action: '',
                destroyComponent : true,
                property_id: '',
                headers: [
                    { text: "Name", value: "name" },
                    { text: "Taxable", value: "taxable" },
                    { text: "Prorate In", value: "in" },
                    { text: "Prorate Out", value: "out" },
                    { text: "", value: "actions", align: "right", sortable: false, width: 10 }
                ],
            }
        },
        components:{
            Modal,
            ProfileList,
            Loader,
            EmptyStateContainer,
            GroupSettings,
            DeleteUpdateModal
        },
        created(){
            this.property_id = this.facility_id || (this.defaultProperty ? this.defaultProperty.id : null);
        },
        computed:{
            ...mapGetters({
                facilityList: 'propertiesStore/filtered',
                defaultProperty: "propertiesStore/defaultProperty"
            }),
            isActionExists() {
                return !!(this.action)
            }
        },
        watch: {
            property_id(p) {
                if(!this.property_id) return;
                this.fetchData();
                this.setDefaultProperty(this.facilityList.find(e => e.id == this.property_id))
            }
        },
        filters:{
           
        },
        methods:{
            ...mapActions({
                setDefaultProperty: 'propertiesStore/setDefaultProperty'
            }),
            async goBack(){
                await this.fetchData();
                this.selected = {};
                this.action = null;
            }, 
            addProfile(){
                this.action = 'add';
            },
            deleteProfile(group){
                this.action = 'delete';
                this.selected = group;
            },
            updateProfile(group){
                this.action = 'update';
                this.selected = group;
            },
            async updateConfirm() {
                try {
                    await this.updateData(this.selected);
                    await this.fetchData();
                    this.showMessageNotification({type: `success`, description: `${this.selected.name} has been updated` });
                    this.closeWindow();
                } catch(err){
                    this.closeWindow();
                    this.showMessageNotification({ description: err });
                }
            },
            async deleteConfirm(){
                try {
                    let r = await api.delete(this, api.PROPERTIES + this.property_id + "/space-groups/", this.selected.id); 
                    await this.fetchData();
                    this.closeWindow();
                    this.showMessageNotification({ type: 'success', description: 'This space grouping has been deleted.' });
                } catch(err){
                    this.closeWindow();
                    this.showMessageNotification({ description: err });
                }
            }, 
            closeWindow(){
                this.profile = {
                    id: '',
                    name: '',
                    description:'',
                }
                this.selected = {};
                this.action = null;
            },
            viewGroup(group){
                this.action = 'view';
                this.selected = group;
            }, 
            async fetchData(){
                let r = await api.get(this, api.PROPERTIES + this.property_id + "/space-groups");
                this.spaceGroups = r.spaceGroups;

            },
            async saveProfile(){
                let status = await this.$validator.validateAll('profile');
                if (!status) {
                    this.showMessageNotification({ type: 'error', description: 'There are errors in your form, correct them before continuing.', list: this.errors.items });
                    return;
                }
                
                try{
                    let payload = {
                        type: 'success',
                        description: ''
                    }
                    if(this.profile.id){
                        let r = await this.updateData(this.profile);
                        payload.description = 'A new space grouping has been edited.'
                    } else {
                        let r = await api.post(this, api.PROPERTIES + this.property_id + "/space-groups", this.profile);
                        payload.description = 'A new space grouping has been created.'
                        this.profile.id = r.space_group.id
                        
                    }
                    this.showMessageNotification(payload);

                    await this.fetchData();
                    let group =  this.spaceGroups.find(ap => ap.id == this.profile.id);
                    this.viewGroup(group); 
                    this.profile = {
                        id: '',
                        name: '',
                        description:'',
                    }
                    
                } catch(err){
                    this.showMessageNotification({ description: err });
                }

              
            },
            async updateData(payload) {
                    const {id} = payload
                    return await api.put(this, api.PROPERTIES + this.property_id + "/space-groups/" + id, payload);
            }
        }
    }
</script>

<style scoped>

</style>

